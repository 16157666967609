import React, { useEffect } from "react";
import { Transition, Button, List } from "semantic-ui-react";

const policyIds = [
  {
    text: "ϟINK Token",
    link: "https://pool.pm/asset1w47qhp0gdlufe37xqx3l7e85m6kutj4l80vgaz",
  },
  {
    text: "OG Ocean Deck",
    link: "https://cardanoscan.io/tokenPolicy/f801a2b6b5fc90042fc1a63bdfefbfacc3440cf2b11494fc4dc7154c",
  },
  {
    text: "OG Avatars",
    link: "https://cardanoscan.io/tokenPolicy/a15ffd725c597503a66c32913cd962b66524fc23628a45ef385b0f00",
  },
];

export default function Policies({ opened = false }) {
  const [visible, setVisible] = React.useState(opened);

  useEffect(() => {
    setVisible(opened);
  }, [opened]);

  function toggleVisibility() {
    setVisible(!visible);
  }
  return (
    <div id="policy-ids">
      <Button
        basic
        fluid
        content="Policy IDs"
        onClick={toggleVisibility}
        icon={`angle ${visible ? "up" : "down"}`}
      />
      <Transition
        visible={visible}
        animation="slide down"
        duration={500}
        unmountOnHide
      >
        <List
          divided
          relaxed
          style={{ textAlign: "left", paddingBottom: 10, color: "#fff" }}
        >
          {policyIds.map((option, idx) => (
            <List.Item key={idx}>
              <List.Icon name="linkify" verticalAlign="middle" />
              <List.Content>
                <List.Header as="a" href={option.link} target="_blank">
                  {option.text}
                </List.Header>
              </List.Content>
            </List.Item>
          ))}
        </List>
      </Transition>
    </div>
  );
}
