import React, { useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Transition, Button } from "semantic-ui-react";

export default function Roadmap({ opened = false }) {
  const [visible, setVisible] = React.useState(opened);

  useEffect(() => {
    setVisible(opened);
  }, [opened]);

  function toggleVisibility() {
    setVisible(!visible);
  }
  return (
    <div id="roadmap">
      <Button
        basic
        fluid
        content="Roadmap"
        onClick={toggleVisibility}
        icon={`angle ${visible ? "up" : "down"}`}
      />
      <Transition
        visible={visible}
        animation="slide down"
        duration={500}
        unmountOnHide
      >
        <StaticImage
          src="../images/roadmap_blank3.png"
          alt="roadmap"
          layout="fullWidth"
        />
      </Transition>
    </div>
  );
}
