import React, { useEffect } from "react";
import { Button, Container, Transition } from "semantic-ui-react";
import Avatars from "./Avatars";
import Decks from "./Decks";

export default function SquidNFTS({ opened = false }) {
  const [visible, setVisible] = React.useState(opened);

  useEffect(() => {
    setVisible(opened);
  }, [opened]);

  function toggleVisibility() {
    setVisible(!visible);
  }
  return (
    <div id="squid-nfts">
      <Container text textAlign="center" style={{ marginBottom: 0 }}>
        <Button
          basic
          fluid
          content="Squid NFTs"
          onClick={toggleVisibility}
          icon={`angle ${visible ? "up" : "down"}`}
        />
      </Container>
      <Transition
        visible={visible}
        animation="fade"
        duration={500}
        unmountOnHide
      >
        <div style={{ marginTop: 20 }}>
          <Avatars />
          <Decks />
        </div>
      </Transition>
    </div>
  );
}
