import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Container } from "semantic-ui-react";
import ExternalLinks from "../components/ExternalLinks";
import Faq from "../components/Faq";
import Tokenomics from "../components/Tokenomics";
import Partners from "../components/Partners";
import Policies from "../components/Policies";
import Roadmap from "../components/Roadmap";
import Intro from "../components/Intro";
import near from "../images/parallax/near.png";
import ocean from "../images/parallax/wave-front.png";
import wave2 from "../images/parallax/wave2.png";
import wave3 from "../images/parallax/wave3.png";
import wave4 from "../images/parallax/wave4.png";
import logo from "../images/squid_poker_logo.png";
import favicon from "../images/favicon.png";
import SquidNFTS from "../components/SquidNFTS";
import GamePlay from "../components/Gameplay";

let lastKnownScrollPosition = 0;
let ticking = false;

const title = "SQUID.POKER";
const content =
  "Squid.poker will be the first provably fair No-Limit Texas hold 'em DAPP/game running on the Cardano blockchain. All the important parts of the poker game will be managed automatically via a Cardano smart contract. (payouts, card randomization, player verification, etc.)";

export default function Index() {
  const [scrollTo, setScrollTo] = useState(null);
  const layer_1 = useRef();
  const layer_2 = useRef();
  const layer_3 = useRef();
  const layer_4 = useRef();
  const layer_5 = useRef();
  const layer_text = useRef();
  useEffect(() => {
    const windowHeight = window.innerHeight * 1.25;
    const padTop = window.innerHeight * 0.05;
    window.addEventListener(
      "scroll",
      () => {
        lastKnownScrollPosition = window.scrollY;
        if (lastKnownScrollPosition > windowHeight) {
          return;
        }
        if (!ticking) {
          window.requestAnimationFrame(() => {
            layer_1.current.style.top = padTop + lastKnownScrollPosition + "px";
            layer_2.current.style.bottom =
              lastKnownScrollPosition * 0.28 + "px";
            layer_3.current.style.bottom =
              lastKnownScrollPosition * 0.25 + "px";
            layer_4.current.style.bottom =
              lastKnownScrollPosition * 0.15 + "px";
            layer_5.current.style.bottom =
              lastKnownScrollPosition * 0.05 + "px";
            layer_text.current.style.bottom =
              lastKnownScrollPosition * 0.05 + "px";
            ticking = false;
          });
          ticking = true;
        }
      },
      false
    );
    const hash = window.location.hash;
    if (hash) {
      const section = hash.replace("#", "");
      setScrollTo(section);
    }
  }, []);
  useEffect(() => {
    if (scrollTo) {
      const el = document.getElementById(scrollTo);
      if (el) {
        el.scrollIntoView(true);
      }
    }
  }, [scrollTo]);
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <link rel="icon" href={favicon} />
        <meta name="title" content={title} />
        <meta name="description" content={content} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://squid.poker/" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={content} />
        <meta property="og:image" content="/images/squid_poker_logo.png" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://squid.poker/" />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={content} />
        <meta property="twitter:image" content="/images/squid_poker_logo.png" />
      </Helmet>
      <div className="parallax">
        <img src={wave4} className="wave4" alt="wave" ref={layer_2} />
        <img src={wave3} className="wave3" alt="wave" ref={layer_3} />
        <img src={logo} className="logo" alt="logo" ref={layer_1} />
        <img src={wave2} className="wave2" alt="wave" ref={layer_4} />
        <img src={ocean} className="ocean" alt="ocean" ref={layer_5} />
        <img src={near} className="near" alt="near" />
        <div className="slogan" ref={layer_text}>
          <p>
            Underwater poker degens coming soon to the Cardano blockchain...
          </p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            height="0"
            width="0"
          >
            <defs>
              <filter id="squiggly-0">
                <feTurbulence
                  id="turbulence"
                  baseFrequency="0.02"
                  numOctaves="3"
                  result="noise"
                  seed="0"
                />
                <feDisplacementMap
                  id="displacement"
                  in="SourceGraphic"
                  in2="noise"
                  scale="6"
                />
              </filter>
              <filter id="squiggly-1">
                <feTurbulence
                  id="turbulence"
                  baseFrequency="0.02"
                  numOctaves="3"
                  result="noise"
                  seed="1"
                />
                <feDisplacementMap in="SourceGraphic" in2="noise" scale="8" />
              </filter>
              <filter id="squiggly-2">
                <feTurbulence
                  id="turbulence"
                  baseFrequency="0.02"
                  numOctaves="3"
                  result="noise"
                  seed="2"
                />
                <feDisplacementMap in="SourceGraphic" in2="noise" scale="6" />
              </filter>
              <filter id="squiggly-3">
                <feTurbulence
                  id="turbulence"
                  baseFrequency="0.02"
                  numOctaves="3"
                  result="noise"
                  seed="3"
                />
                <feDisplacementMap in="SourceGraphic" in2="noise" scale="8" />
              </filter>
              <filter id="squiggly-4">
                <feTurbulence
                  id="turbulence"
                  baseFrequency="0.02"
                  numOctaves="3"
                  result="noise"
                  seed="4"
                />
                <feDisplacementMap in="SourceGraphic" in2="noise" scale="6" />
              </filter>
            </defs>
          </svg>
        </div>
        <a href="#https://app.squid.poker" className="mint-now">
          <button className="caption rb">
            <span>L</span>
            <span>A</span>
            <span>U</span>
            <span>N</span>
            <span>C</span>
            <span>H</span>
            <span>&nbsp;</span>
            <span>A</span>
            <span>P</span>
            <span>P</span>
          </button>
        </a>
      </div>
      <Container text textAlign="center" style={{ marginBottom: 0 }}>
        <Intro />
        <Policies opened={scrollTo === "policies"} />
        <Faq opened={scrollTo === "faq"} />
        <GamePlay opened={scrollTo === "gameplay-preview"} />
        <ExternalLinks opened={scrollTo === "external-links"} />
      </Container>
      <SquidNFTS opened={scrollTo === "squid-nfts"} />
      <Container text textAlign="center" style={{ marginBottom: 0 }}>
        <Tokenomics opened={scrollTo === "tokenomics"} />
        <Roadmap opened={scrollTo === "roadmap"} />
        <Partners opened={scrollTo === "partners"} />
      </Container>
      <footer>
        Copyright &copy; 2022,{" "}
        <a className="sp" href="https://squid.poker">
          Squid.poker
        </a>
      </footer>
    </div>
  );
}
