import React, { useState } from "react";
import { Segment, Icon } from "semantic-ui-react";

export default function Intro() {
  const [readMore, toggleReadMore] = useState(false);
  return (
    <div className="header">
      <Segment className="top-segment">
        <div className="social">
          <a href="https://discord.gg/7XkCTtjteR" className="social-icon nu">
            <Icon name="discord" />
            <span>discord</span>
          </a>
          <a href="https://twitter.com/squid_poker" className="social-icon nu">
            <Icon name="twitter" />
            <span>twitter</span>
          </a>
          <a href="/#" className="social-icon nu disabled">
            <Icon name="file outline" />
            <span>squid paper</span>
          </a>
        </div>
        <p>
          <a className="sp" href="https://squid.poker">
            Squid.poker
          </a>{" "}
          will be the first provably fair No-Limit Texas Hold’em poker app
          running on the Cardano blockchain. To play on
          {readMore && (
            <>
              {" "}
              <a className="sp" href="https://squid.poker">
                Squid.poker
              </a>{" "}
              you will need to have <span className="pink">1</span> of our 100%
              on-chain avatars and our token <span className="green">ϟINK</span>
              . These avatars will be used to verify players playing on our
              poker app and the only way to play is by owning one. To mint an OG
              Avatar click the SQUID NFTS drop down below and click the Mint
              buttons.{" "}
            </>
          )}
          {!readMore && (
            <button className="read-more" onClick={() => toggleReadMore(true)}>
              Read more...
            </button>
          )}
        </p>
        {readMore && (
          <>
            <br />
            <p>
              You will also need an{" "}
              <a href="https://adahandle.com">ADAhandle.com</a> for your
              username. We will be periodically releasing limited edition
              avatars, decks, and table customizations based on player demand.
              Owning one of our OG Ocean Deck NFTs below will unlock the full OG
              Ocean Deck and Stone table you see under the Gameplay Preview
              menu.
            </p>
          </>
        )}
        <br />
      </Segment>
    </div>
  );
}
