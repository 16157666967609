import React, { useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Image, Button, Transition, Icon } from "semantic-ui-react";
import ink from "../images/logo.png";

export default function Tokenomics({ opened = false }) {
  const [visible, setVisible] = React.useState(opened);

  useEffect(() => {
    setVisible(opened);
  }, [opened]);

  function toggleVisibility() {
    setVisible(!visible);
  }

  return (
    <div id="tokenomics">
      <Button
        basic
        fluid
        content={
          <span className="s-header">
            <span>ϟ</span> Tokenomics
          </span>
        }
        onClick={toggleVisibility}
        icon={`angle ${visible ? "up" : "down"}`}
      />
      <Transition
        visible={visible}
        animation="slide down"
        duration={500}
        unmountOnHide
      >
        <div className="ink">
          <Image src={ink} width={200} height="auto" centered />
          <p>
            The only way to play on{" "}
            <a className="sp" href="https://squid.poker">
              Squid.poker
            </a>{" "}
            is by grabbing some{" "}
            <a
              href="https://app.minswap.org/"
              style={{
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <span className="green">ϟINK</span>
              <span>&nbsp;</span>
              <Icon name="external" size="tiny" color="green" />
            </a>
          </p>
          <br />
          <a
            className="chart"
            href="https://pool.pm/asset1w47qhp0gdlufe37xqx3l7e85m6kutj4l80vgaz"
          >
            <StaticImage
              src="../images/piechart.png"
              alt="pie chart"
              width={500}
              height={460}
            />
            <p>
              Total Supply: <span className="green">999,999,999.999</span>
              <br />
              Policy Locked: 6/1/2022
            </p>
          </a>
        </div>
      </Transition>
    </div>
  );
}
