import React, { useState } from "react";
import { Container, Modal } from "semantic-ui-react";

const avatars = {
  jokers: ["hydra", "medusa", "jester", "jinx"],
  aces: [
    "spy",
    "vampire",
    "gorilla",
    "snail",
    "samurai",
    "ninja",
    "blobfish",
    "mermaid",
  ],
  kings: [
    "gnome",
    "green_alien",
    "cyclops",
    "fairy",
    "killer_whale",
    "easter_island_statue",
    "gargoyle",
    "hammerhead",
    "sphinx",
    "swimsuit_model",
    "walrus",
    "slime_monster",
  ],
  queens: [
    "lobster",
    "dolphin",
    "dodo",
    "lochness",
    "peacock",
    "knight",
    "llama",
    "sea_turtle",
    "seahorse",
    "plant_monster",
    "narwhal",
    "octopus",
    "princess",
    "shark",
  ],
  jacks: [
    "8-Ball",
    "atm",
    "donkey",
    "fish",
    "penguin",
    "snowman",
    "starfish",
    "brain",
    "eyeball",
    "cow",
    "jellyfish",
    "seal",
    "unicorn",
    "slot_machine",
    "ostrich",
    "raccoon",
  ],
};

export default function Avatars() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="avt">
      <div className="text-center">
        <h3 className="section-subheader">Season 1:</h3>
        <h1 className="section-header">OG Avatars</h1>
      </div>
      <div className="avatars">
        {Object.keys(avatars).map((type) => (
          <div className={type} key={type}>
            <div className="title">
              <span>{type}</span>
            </div>
            {avatars[type].map((avatar) => (
              <div className="avatar" key={avatar}>
                <img
                  src={"/images/avatars/" + type + "/" + avatar + ".png"}
                  alt={avatar + " " + type}
                />
                <strong>{avatar.replace(/_/g, " ")}</strong>
              </div>
            ))}
          </div>
        ))}
      </div>
      <Container text textAlign="center">
        <button className="caption pu" onClick={handleOpen}>
          CLICK HERE TO VIEW MORE INFORMATION ABOUT THE AVATARS
        </button>
        <p className="av-cap">
          To mint an OG Avatar please click the link below!
        </p>
        <a href="https://squid.starforge.me">
          <button className="caption rb">Mint OG Avatar</button>
        </a>
      </Container>
      <Modal open={open} size="mini" closeIcon onClose={handleClose}>
        <Modal.Content>
          <table className="rarity">
            <thead>
              <tr>
                <th>Avatars</th>
                <th>Qty.</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Jokers</td>
                <td>&nbsp;4 &times; 13 = &nbsp;52</td>
              </tr>
              <tr>
                <td>Aces</td>
                <td>&nbsp;8 &times; 13 = 104</td>
              </tr>
              <tr>
                <td>Kings</td>
                <td>12 &times; 13 = 156</td>
              </tr>
              <tr>
                <td>Queens</td>
                <td>14 &times; 13 = 182</td>
              </tr>
              <tr>
                <td>Jacks</td>
                <td>16 &times; 13 = 208</td>
              </tr>
              <tr>
                <th>Total</th>
                <th>702</th>
              </tr>
            </tbody>
          </table>
        </Modal.Content>
      </Modal>
    </div>
  );
}
