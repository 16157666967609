import React, { useEffect } from "react";
import { Transition, Button, List } from "semantic-ui-react";

export default function ExternalLinks({ opened = false }) {
  const [visible, setVisible] = React.useState(opened);

  useEffect(() => {
    setVisible(opened);
  }, [opened]);

  function toggleVisibility() {
    setVisible(!visible);
  }
  return (
    <div id="external-links">
      <Button
        basic
        fluid
        content="External Links"
        onClick={toggleVisibility}
        icon={`angle ${visible ? "up" : "down"}`}
      />
      <Transition
        visible={visible}
        animation="slide down"
        duration={500}
        unmountOnHide
      >
        <List
          divided
          relaxed
          style={{ textAlign: "left", paddingBottom: 10, color: "#fff" }}
        >
          <List.Item>
            <List.Icon name="linkify" verticalAlign="middle" />
            <List.Content>
              <List.Header
                as="a"
                href="https://www.jpg.store/collection/squidpokerseason1-ogavatars"
                target="_blank"
              >
                JPG.store (OG Avatars)
              </List.Header>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="linkify" verticalAlign="middle" />
            <List.Content>
              <List.Header
                as="a"
                href="https://www.jpg.store/collection/squidpokerseason1-ogoceandeck"
                target="_blank"
              >
                JPG.store (OG Ocean Decks)
              </List.Header>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="linkify" verticalAlign="middle" />
            <List.Content>
              <List.Header>
                <span style={{ color: "#fff" }}>
                  If you like on-chain games check out
                </span>{" "}
                <a
                  href="https://chesscnft.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  ChessCNFT.com
                </a>
              </List.Header>
            </List.Content>
          </List.Item>
        </List>
      </Transition>
    </div>
  );
}
