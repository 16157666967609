import React, { useEffect } from "react";
import { Transition, Button, Image } from "semantic-ui-react";
import skulpool from "../images/skull_pool.gif";
import ant_biz from "../images/anti.biz.png";

export default function Policies({ opened = false }) {
  const [visible, setVisible] = React.useState(opened);

  useEffect(() => {
    setVisible(opened);
  }, [opened]);

  function toggleVisibility() {
    setVisible(!visible);
  }
  return (
    <div id="partners">
      <Button
        basic
        fluid
        content="Partners"
        onClick={toggleVisibility}
        icon={`angle ${visible ? "up" : "down"}`}
      />
      <Transition
        visible={visible}
        animation="slide down"
        duration={500}
        unmountOnHide
      >
        <div className="partners">
          <a href="https://adapools.org/pool/bbd852e7fb67e8f182def1f99d22a51c76b40222ab47d0682223e606">
            <Image src={skulpool} className="skull" />
          </a>
          <br />
          <a href="https://anti.biz">
            <Image src={ant_biz} className="anti" />
          </a>
        </div>
      </Transition>
    </div>
  );
}
