import React, { useEffect } from "react";
import {
  Transition,
  Button,
  Accordion,
  Icon,
  Divider,
} from "semantic-ui-react";

export default function Faq({ opened = false }) {
  const [visible, setVisible] = React.useState(opened);
  const [activeIndex, setActiveIndex] = React.useState(-1);

  useEffect(() => {
    setVisible(opened);
  }, [opened]);

  function toggleVisibility() {
    setVisible(!visible);
  }
  function handleClick(_, titleProps) {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  }
  return (
    <div style={{ marginBottom: 0 }} id="faq">
      <Button
        basic
        fluid
        content="FAQ"
        onClick={toggleVisibility}
        icon={`angle ${visible ? "up" : "down"}`}
      />
      <Transition
        visible={visible}
        animation="slide down"
        duration={500}
        unmountOnHide
      >
        <Accordion className="acc" fluid>
          {faqs.map((faq, index) => (
            <React.Fragment key={index}>
              <Accordion.Title
                active={activeIndex === index}
                index={index}
                onClick={handleClick}
                style={{ texAlign: "left" }}
              >
                <Icon name="dropdown" />
                <span dangerouslySetInnerHTML={{ __html: faq.q }} />
              </Accordion.Title>
              <Accordion.Content active={activeIndex === index}>
                <p dangerouslySetInnerHTML={{ __html: faq.a }}></p>
              </Accordion.Content>
              <Divider />
            </React.Fragment>
          ))}
        </Accordion>
      </Transition>
    </div>
  );
}
const faqs = [
  {
    q: "How do I mint an Avatar or Deck?",
    a: 'Click the Mint buttons under the Squid NFTS menu.<br/><br/>There will be <span class="pink">2</span> separate payment links that goto our minting page for the Avatars & Deck.<br/><br/>We recommend using <a href="https://eternl.io" target="_blank">Eternl.io</a> wallet to mint.<br/><br/>Do Not send <span class="pink">₳ADA</span> from an exchange or your NFT/FT maybe lost!',
  },
  {
    q: "Why did you choose pixel art for your poker site when all other poker apps use a illustrative style?",
    a: 'We wanted all the assets to be <span class="pink">100%</span> on-chain and not rely on any off-chain art storage like Arweave or IPFS. Also, we think pixel art is timeless, and can look retro and futuristic at the same time.<br/><br/>We plan to release new Avatars/Decks/Table skins in the future done by various pixel artist, so that you can really stand out at the table having something someone else doesn\'t.<br/><br/>The color palette we chose is this <span class="pink">16</span> color Commodore <span class="pink">64</span> inspired palette. In the future we would like to release different color palette avatars/tables/decks so that you can have different matching themes to play with.<br/><br/>Currently we are going for an old school vibe to start with. We think it’s something never before seen in the online poker world a fully pixelated online poker experience.  Another benefit is you can scale pixel art indefinitely which allows for a great UX experience when you don\'t get any degradation of image quality!',
  },
  {
    q: "What do I get if I mint a Season 1 Avatar/Deck?",
    a: "Owning a single card from the Season 1 OG Ocean Deck will unlock the full deck to play with on the website. If you own an OG Avatar or OG Ocean Deck NFT you will unlock the Stone table skin customization. <br/><br/>The Stone table/buttons will not be the default skin for the DAPP once we launch. We will be showing previews of the default table skins in the near future. We will also have some free customizations like changing the center of the table color or the color of the back of your cards for free in future versions of Squid.poker!",
  },
  {
    q: "Do you plan to sponsor professional poker players or influencers in the future?",
    a: "Yes! Squid poker is a registered trademark and once the DAPP/game launches and we start bringing in consistent funds we will be looking to get our name out there with players rocking our logo out and about at different events!",
  },
  {
    q: "Will Squid Poker be a downloadable application?",
    a: 'This will be one of our main goals to release the game on multiple devices, so that you can connect and play from anywhere. The first/main release will be done on our website <a href="https://squid.poker" class="sp">Squid.poker</a> and eventually we will have a downloadable game that will work on your computer or various devices.',
  },
  {
    q: "How much rake will the site be taking?",
    a: 'Cash games rake is still undetermined but should be less, or equal to the standard amount found on top poker gambling websites. For Tournaments, Sit & Gos it will be 1/10th the cost of each buy-in for each game. For example, the buy-in for the game is <span class="green">10,000 ϟINK</span> it would be + <span class="green">1,000 ϟINK</span> that goes back to us.<br/><br/>For more information check out our Squid Paper.',
  },
  {
    q: "Do you plan to host special tournaments or giveaways?",
    a: "We will be doing special tournaments & giveaways where you could win a 1/1 Avatar that no one else would own but you! We will also make special avatars for any pros that join the site looking to represent how they look in real life. <br/><br/>There will also be ways to earn points to unlock new customizations or your own custom 1/1 Avatar in the future! <br/><br/>We plan to have a store as well, so that you can get some high-quality merchandise featuring our logos, and/or artwork! ",
  },
  {
    q: "Will there be other ways to stand out at the poker tables besides Avatars?",
    a: "We want to implement a ranking system where it will highlight the best players on our website. We would also like to reward top players monthly with special customizations that no other players have like new highlight/name colors or custom avatars, etc.",
  },
  {
    q: "I am an experience coder/developer and I want to join the team how do I do that?",
    a: "We are constantly on the lookout for talented new team members that can help make this project the best it can be as well as new partnerships with projects running on the Cardano blockchain.<br/><br/>Just message dii#3452 in the Squid Poker Discord if you want to send in your resume.",
  },
  {
    q: "Isn't gambling illegal?",
    a: "It can be depending on your local or country laws. We plan to follow all laws required for running an online gambling website legally. Which includes expensive licensing cost. This is where our NFT/Token sales will help pay all of our legal & development cost we incur along the way.",
  },
  {
    q: 'Will you be able to play on Squid Poker using <span class="pink">₳ADA?</span>',
    a: 'At the moment the only way to play will be using the <span class="green">ϟINK</span> token, you will be able to get <span class="green">ϟINK</span> through the faucet on our website, air drops, various exchanges or giveaways. We may include play with <span class="pink">₳ADA</span> in the future. Or have a direct exchange on our website, so that you can easily swap into our token.',
  },
  {
    q: 'Whats the total supply of the <span class="green">ϟINK</span> token?',
    a: '<span class="green">999,999,999.999</span><br/><br/>Nine hundred ninety-nine million, nine hundred ninety-nine thousand, nine hundred ninety-nine. And <span class="pink">3</span> decimal places of <span class="pink">.999</span>.<br/><br/>The policy for <span class="green">ϟINK</span> was locked on 6/1/2022 and can never be changed, so it will always have a total supply of <span class="green">999,999,999.999</span>.<br/><br/>The only thing that can be changed is the image of the FT (fungible token). Check the token out here <a href="https://pool.pm/asset1w47qhp0gdlufe37xqx3l7e85m6kutj4l80vgaz" target="_blank"><span class="pid">https://pool.pm/asset1w47qhp0gdlufe37xqx3l7e85m6kutj4l80vgaz</span></a><br/><br/>You can see our planned distribution on our website <span class="green">ϟINK</span> pie graph.',
  },
  {
    q: "What should I look out for in the near future?",
    a: 'We will be updating our website with more aesthetics, like a night/day mode. And improving/adding more information as we make progress on the game. We will also be posting updates in an update section on the website.<br/><br/>These updates will also be posted to our <a href="https://twitter.com/squid_poker">Twitter</a> & <a href="https://discord.gg/7XkCTtjteR">Discord</a>.',
  },
  {
    q: "I'm impatient and want to play poker now how do I do that?",
    a: 'We have a simple poker bot currently in our Squid Poker Discord that people can use to play using our <span class="green">ϟINK</span> token for fun to pass the time and chat with likeminded people!<br/><br/>We will also be hosting tournaments on this site <a href="https://www.pokernow.club/" target="_blank">www.pokernow.club</a> where our community has a chance to win free INK!',
  },
];
