import React from "react";
import { Container } from "semantic-ui-react";

const signs = ["clubs", "diamonds", "hearts", "spades"];
const types = [2, 3, 4, 5, 6, 7, 8, 9, 10, "ace", "king", "queen", "jack"];
const decks = [];
types.forEach((type) => {
  signs.forEach((sign) =>
    decks.push("/images/deck/" + type + "_of_" + sign + ".png")
  );
});
["black", "blue", "gray", "green", "purple", "red", "teal", "yellow"].forEach(
  (color) => decks.push("/images/deck/" + color + "_card_back.png")
);

export default function Decks() {
  return (
    <div>
      <div className="text-center">
        <h3 className="section-subheader">Season 1:</h3>
        <h1 className="section-header">OG Ocean Deck</h1>
      </div>
      <div className="avatars">
        {decks.map((card, idx) => (
          <div className="avatar" key={idx}>
            <img src={card} alt="card" />
          </div>
        ))}
      </div>
      <Container text textAlign="center">
        <p className="details border">
          There are <span className="pink">52</span> cards which includes{" "}
          <span className="pink">13</span> of each suit{" "}
          <span role="img">♥</span>, <span role="img">♦</span>,{" "}
          <span role="img">♣</span>, <span role="img">♠</span> +{" "}
          <span className="pink">8</span> different colored card backs with a
          supply of <span className="pink">4</span>. By owning a single card
          from the OG Ocean deck you will own the entire custom deck! There is a
          max of <span className="pink">13</span> copies of each card for a
          total of <span className="pink">708</span>! All of our custom decks
          will be equally limited to this same amount, so grab a card before
          they're all gone!
        </p>
        <p className="details">
          Please click the link below to mint an OG Ocean Deck card!
        </p>
        <a href="https://squid.starforge.me">
          <button className="caption rb">Mint OG Ocean Deck</button>
        </a>
      </Container>
      <br />
      <br />
    </div>
  );
}
