import React, { useEffect } from "react";
import { Button, Image, Modal, Transition } from "semantic-ui-react";
import tblMin from "../images/squid_poker_table-min.gif";
import tbl from "../images/squid_poker_table.gif";

export default function GamePlay({ opened = false }) {
  const [visible, setVisible] = React.useState(opened);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setVisible(opened);
  }, [opened]);

  function toggleVisibility() {
    setVisible(!visible);
  }
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div id="gameplay-preview">
      <Button
        basic
        fluid
        content="Gameplay Preview"
        onClick={toggleVisibility}
        icon={`angle ${visible ? "up" : "down"}`}
      />
      <Transition
        visible={visible}
        animation="fade"
        duration={500}
        unmountOnHide
      >
        <div className="poker-table" style={{ marginTop: 20 }}>
          <img
            src={tblMin}
            alt="poker table"
            className="poker"
            onClick={handleOpen}
            role="presentation"
            aria-hidden="true"
          />
          <Modal
            open={open}
            size="large"
            className="pk"
            closeIcon
            onClose={handleClose}
          >
            <Image fluid src={tbl} alt="poker table" />
          </Modal>
        </div>
      </Transition>
    </div>
  );
}
